.main-card-createPerson__container {
  overflow-y: auto;
  max-height: 80vh;

  .main-card-createPerson {
    width: 50vw;
    padding: 3vmin;
    margin: 5px auto;
    justify-content: center;
    margin-bottom: 30px;
    box-shadow: 5px 5px 15px 5px rgba(0, 0, 0, 0.15);
    .form-createPerson {
      display: inline-block;
      width: 75%;
      margin: 3vmin;
      justify-items: center;
      .ant-row.ant-form-item {
        margin: auto;
        max-width: 400px;
      }
    }
    .ant-card-head-title {
      white-space: unset;
    }
    p {
      margin-bottom: 30px;
    }

    .save-personal-data-button {
      margin-top: 15px;
    }
    @media screen and (max-width: 768px) {
      width: 85vw;
    }
  }
}

@primary-color: #454f58;