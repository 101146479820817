.container-event {
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: white;
  .banner-container {
    width: 100%;
    max-height: 50%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    .banner-loading {
      height: 200px;
    }
    img.banner {
      max-height: 50vh;
      width: 100%;
      object-fit: contain;
    }
  }
  .event-section {
    padding: 20px 10vw;
    background-color: white;
  }
  .subscription-section {
    display: flex;
    flex-direction: column;
    background-color: white;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.25);
    clip-path: inset(0px 0px -15px 0px);
    position: relative;
    z-index: 2;
    h2 {
      margin: 0;
    }
    box-sizing: border-box;
    .subscription-item {
      border-top: solid rgba(0, 0, 0, 0.25) 1px;
      padding: 20px 10vw;
      display: flex;
      gap: 8px;
      justify-content: space-between;
      align-items: center;
      > div {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 8px;
        justify-content: space-between;
        @media (max-width: 700px) {
          gap: 0;
        }
      }
      .select-button {
        flex: 1;
        border-radius: 50px;
        width: 200px;
        font-size: 16px;
        font-weight: 600;
        height: 2.5em;
        margin-top: 10px;
      }
    }
  }
  .details-section {
    font-weight: 500;
    .details-item {
      margin-top: 20px;
      + .details-item {
        margin-top: 10px;
      }
    }
    .description-item {
      margin-top: 50px;
      white-space: pre-line;
    }
  }
}

@media (max-width: 700px) {
  .container-event {
    .subscription-section {
      .subscription-item {
        display: grid;
      }
    }
  }
}

@primary-color: #454f58;