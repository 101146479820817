.ant-modal.modal-auth-link {
  .ant-modal-confirm-content {
    width: 100%;
    padding-right: 3rem;
    .ant-typography-copy .anticon {
      margin-top: 0;
    }
  }
}
.tenant-image-preview {
  min-width: 50vw;
  min-height: 50vh;
}

@primary-color: #454f58;