@import '../../theme/variables.css';
.pay-disapproved {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  padding: 20px;
  > .pay-disapproved__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    > .pay-disapproved__container__container-icon {
      margin-bottom: 25px;
      > .pay-disapproved__container__container-icon__icon {
        font-size: 2rem;
        font-weight: 500;
        color: black;
        animation-name: floating;
        animation-duration: 0.5s;
        animation-iteration-count: 1;
        animation-timing-function: cubic-bezier(0.19, -0.03, 0, 0.53);
      }
    }
    > .pay-disapproved__container__container-title {
      text-align: center;
      margin-bottom: 25px;
      > span {
        font-size: 2rem;
        font-weight: 500;
        font-size: 1.8rem;
      }
    }
    > .pay-disapproved__container__container-subtitle {
      text-align: center;
      margin-bottom: 25px;
      > span {
        font-weight: 200;
        font-size: 0.8rem;
      }
    }
    > .pay-disapproved__container__container-button {
      width: 100%;
      text-align: center;
      height: max-content;
      > button {
        border-radius: 500px;
        background-color: var(--events-color-primary);
        color: white;
        width: 80%;
        padding: 10px;
        height: auto;
        > span {
          font-weight: bold;
        }
      }
    }
  }
}
@keyframes floating {
  0% {
    transform: translate(0, -30px);
  }
  100% {
    transform: translate(0, 0px);
  }
}

@primary-color: #454f58;