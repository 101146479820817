.QrCode {
  .qr-img {
    width: 100%;
    height: 100%;
  }

  .qr-modal {
    text-align: center;
  }
}

@primary-color: #454f58;