.messageRegExp {
  position: relative;
  display: flex;
  flex-direction: row;
  flex: 3;
  justify-content: flex-end;
  .textRegExp {
    text-align: left;
    font-size: 12px;
    margin-bottom: 0;
  }
}

@primary-color: #454f58;