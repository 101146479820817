.Login {
  display: flex;
  flex-direction: column;
  align-items: center;
  .login-form {
    max-width: 300px !important;
    margin: auto !important;
    width: 100%;
    .ant-row {
      margin-bottom: initial !important;
    }

    .ant-form-item-explain {
      margin: 5px initial !important;
    }

    .ant-col {
      height: 60px !important;
    }

    .login-form-forgot {
      float: right !important;
    }

    .ant-col-rtl .login-form-forgot {
      float: left !important;
    }

    .login-form-button {
      width: 100% !important;
    }
    .ant-row.ant-form-item {
      height: 65px;
    }
  }
  .container-buttons-login {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    margin-top: 40px;
    max-width: 300px;
  }
}

@primary-color: #454f58;