@import '~antd/lib/style/themes/default.less';

.RegisteredLayout {
  height: 100%;
  > aside.ant-layout-sider {
    overflow: auto;
    height: 100%;
    position: fixed;
    left: 0;
    color: white;
    white-space: nowrap;
    z-index: 6;
  }
  > section.ant-layout {
    color: inherit;
    height: 100%;
    overflow-y: auto;
    > header.ant-layout-header {
      background-color: white;
      padding-left: 48px;
    }
    > .backdrop-content {
      display: none;
      width: 100%;
      height: 100%;
      background-color: rgba(66, 66, 66, 0.589);
      position: absolute;
      z-index: 5;
    }
    > .ant-layout-content {
      position: relative;
      overflow: initial;
      margin: 24px;
    }
    transition: all 0.2s;
  }
}

@media screen and (max-width: 400px) {
  .RegisteredLayout {
    > section.ant-layout {
      > header.ant-layout-header {
        padding-right: 24px;
      }
      > .ant-layout-content {
        margin: 0px;
      }
    }
  }
}
@media (max-width: 400px) {
  .ant-layout-header.header-component {
    padding: 0px 20px !important;
    .ant-layout-content {
      margin: 0px;
    }
    .header-component--container-menu {
      .AvatarName {
        display: none;
      }
    }
  }
}

@primary-color: #454f58;