.container_set_password_content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  > .container_text_set_password_content {
    max-width: 300px !important;
    color: black;
  }
  .container_form_set_password_content {
    width: 100%;
    > form {
      margin: 0 auto;
      max-width: 300px !important;
      > .items {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        margin-bottom: 4vh;
        margin-top: 3vh;
      }
      > .ant-form-item-control {
        justify-content: center;
      }
      .btn_set_password {
        width: 100%;
      }

      @media (max-width: 256px) {
        .items {
          min-height: 40vh;
        }
      }
    }
  }
}

@primary-color: #454f58;