.main-card-validate2FA {
  width: 85%;
  margin: auto;
  box-shadow: 5px 5px 15px 5px rgba(0, 0, 0, 0.15);
  p {
    margin-bottom: 10px;
  }

  .input-token {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    > .container-input {
      margin-top: 10px;
      height: 70px;

      > .warning-input {
        font-size: 10px;
        color: #ff0000;
      }
    }

    > .container-buttons {
      display: flex;
    }
  }

  @media screen and (max-width: 370px) {
    .ant-card-body {
      padding: 16px;
    }

    .input-token {
      > .container-input {
        .react-code-input {
          input {
            height: 30px !important;
            width: 30px !important;
            margin: 2px !important;
            padding-left: 5px !important;
          }
        }
      }
    }
  }
}

@primary-color: #454f58;