.container_reset_password_content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 70vh;
  img {
    height: 20%;
    object-fit: contain;
  }
  > .container_text_reset_password_content {
    max-width: 300px !important;
    color: black;
  }
  .container_form_reset_password_content {
    width: 100%;

    > form {
      margin: 0 auto;
      max-width: 300px !important;
      > .items {
        display: flex;
        flex-direction: column;
        min-height: 35vh;
        margin-top: 2rem;
      }
      > .ant-form-item-control {
        justify-content: center;
      }
      .btn_reset_password {
        width: 100%;
        margin-bottom: 1.6rem;
      }
      .btn_back {
        width: 100%;
      }
      @media (max-width: 256px) {
        .items {
          min-height: 40vh;
        }
      }
    }
  }
}

@primary-color: #454f58;