.container-form-profile {
  display: flex;
  justify-content: center;
  > .content-container-form-profile {
    width: 50%;
    .content-container-center {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
  }

  .qr-code {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
  }

  .ant-form {
    > button,
    > div {
      margin-bottom: 20px;
    }
    .buttons {
      display: flex;
      justify-content: flex-start;
      .ant-row,
      button {
        margin-right: 10px;
      }
    }
  }

  .container-avatar {
    position: relative;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;

    .avatar {
      position: relative;
      right: -50%;
    }
    .button {
      width: 130px;
      height: 130px;
      position: relative;
      opacity: 0%;
      right: 50%;
      z-index: 3;
      border-radius: 65px;
      &.show-button:hover {
        position: relative;
        opacity: 70%;
        background-color: white;
      }
    }
  }

  @media (max-width: 1100px) {
    .content-container-form-profile {
      width: 75%;
    }
    .ant-form {
      .buttons {
        .ant-row,
        button {
          width: 100%;
          margin-right: 0px;
        }
      }
    }
  }
  @media (max-width: 600px) {
    .content-container-form-profile {
      width: 100%;
    }
  }
}

@primary-color: #454f58;