.inner_form_item {
  margin: 0;
  &.form_item_checkbox {
    flex-direction: row-reverse !important;
    justify-content: flex-end;
    gap: 10px;
    .ant-col.ant-form-item-label {
      flex: unset !important;
      flex-shrink: 0 !important;
      ::after {
        display: none;
      }
    }
    .ant-col.ant-form-item-control {
      flex: unset !important;
      flex-shrink: 0 !important;
      width: auto !important;
    }
  }
}

@primary-color: #454f58;