.AboutPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 500px;
  height: 65%;
  margin: auto;
  text-align: center;
  z-index: 1 !important;
}

@primary-color: #454f58;