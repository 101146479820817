@import '~antd/dist/antd.less';

.checkticket {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100%;
  position: relative;
  gap: 20px;

  p {
    margin: 0;
  }

  #video {
    width: 100%;
    height: 100%;
    position: relative;
  }

  .qr-guide-container {
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .qr-guide {
      width: min(50vw, 50vh);
      height: min(50vw, 50vh);
    }
  }
}

.cut-ticket {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-height: 100%;
  position: relative;
  gap: 20px;

  .ticket-container {
    background: @layout-body-background;
    margin-top: 20px;
    width: min(80%, 1000px);
    text-align: left;
    border: solid 1px;
    border-radius: 5px;

    hr {
      margin: 0;
    }
  }
  .cut-ticket-button {
    margin-bottom: 20px;
    padding: 5px 15px;
    color: white;
    background: #5d9cec;
    border-radius: 500px;
    font-size: 20px;
    font-weight: 700;
    height: 50px;
    width: min(80%, 400px);
  }
  p {
    margin: 0;
  }
  .section {
    .personal-info-section {
      font-size: 1.5rem;
    }
    margin: 20px;
  }
}

.ckeck-ticket-error-content {
  display: grid;
  place-items: center;
  height: 100%;
  .check-ticket-error-inner-content {
    margin: auto 1rem;
  }
}

@primary-color: #454f58;