.container-purchase {
  justify-content: space-between;
  align-items: center;
  height: 100%;
  font-family: 'Work Sans';
  background-color: white;
  .purchase-section {
    padding: 2vw 10vw;
    background-color: white;
    display: flex;
    justify-content: space-between;
    .input-data-section {
      padding: 2vmin;
      width: 50%;
      .input-data {
        max-width: 300px;
        background: #fdfdfd;
        border: 1px solid #eeeeee;
        border-radius: 10px;
      }
    }
    .card-event-section {
      width: fit-content;
      .card-event-mobile-text {
        display: block;
        padding-bottom: 25px;
      }
      .card-event-summary {
        width: 100%;
        margin: 20px;
      }
      .ant-card.event-card-summary.event-card-summary--cursor-default {
        transform: rotate(6.76deg);
      }
      .mercadopago-button,
      .purchase-button {
        background: #5d9cec;
        border-radius: 500px;
        border: none;
        margin: 5vmin auto 1vmin;
        display: flex;
        align-self: center;
        width: 90%;
        max-width: 250px;
        justify-content: center;
      }
      .mercadopago-button {
        font-size: 16px;
        font-weight: bold;
      }
    }
  }
  .purchase-button {
    border-radius: 50px;
    width: 200px;
    font-size: 16px;
    font-weight: 600;
    height: 2.5em;
  }
  .details-section {
    font-weight: 500;
    .details-item {
      margin-top: 20px;
      + .details-item {
        margin-top: 10px;
      }
    }
    .description-item {
      margin-top: 50px;
    }
  }
}

@media (max-width: 700px) {
  .container-purchase {
    .purchase-section {
      padding: 20px 5vw;
      display: flow-root;
      .input-data-section {
        width: 100%;
        padding: 0vmin;
        .input-data {
          max-width: 100%;
        }
        .purchase-title {
          .ant-typography {
            font-size: 20px !important;
          }
        }
      }
      .card-event-section {
        width: 100%;
        .card-event-mobile-text {
          padding-bottom: 30px;
        }
        .card-event-summary {
          width: 80%;
          margin: auto auto;
          .purchase-button {
            margin-top: 30px;
          }
        }
      }
    }
  }
}

@primary-color: #454f58;