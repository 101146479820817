div.ManagementUsersLayout {
  text-align: center;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  .env-tag {
    position: absolute;
    top: 15px;
    right: 15px;
  }
  div.ManagementUsersLayoutTitle {
    line-height: 20vh;
    font-size: 5vh;
    font-weight: 600;
  }
  div.ManagementUsersLayoutContent {
    flex: 2;
  }
  div.ManagementUsersLayoutFooter {
    flex: 0;
    margin-bottom: 24px;
  }
}

@primary-color: #454f58;