.ant-card.event-card {
  min-width: 300px;
  width: 100%;
  min-height: 310px;
  height: 100%;
  max-height: 40rem;
  border-radius: 10px;
  &.event-card--cursor-default {
    cursor: default;
  }
  .ant-card-cover {
    position: relative;
    .event-card__image {
      width: 100%;
      height: 150px;
      object-fit: cover;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }
    .event-card__price {
      width: fit-content;
      font-weight: bold;
      position: absolute;
      top: 10px;
      left: 10px;
      display: block;
      border-radius: 5px;
      padding: 5px;
      background-color: white;
    }
  }
  .ant-card-body {
    .ant-card-meta-avatar {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-right: 10px;
      .event-card__meta-month {
        margin-top: 3px;
        font-weight: bold;
      }
      .event-card__meta-day {
        font-weight: bold;
        font-size: 1.4rem;
      }
    }
    .ant-card-meta-title {
      .event-card__meta-container-title {
        display: flex;
        flex-direction: column;
        white-space: break-spaces;
        > .event-card__meta-container-title__label {
          font-weight: normal;
        }
      }
    }
    .event-card__button {
      border: none;
      color: white;
      position: absolute;
      bottom: -20px;
      right: 25px;
      &:hover {
        border-color: transparent;
        box-shadow: 0 1px 2px -2px rgb(0 0 0 / 16%),
          0 3px 6px 0 rgb(0 0 0 / 12%), 0 5px 12px 4px rgb(0 0 0 / 9%);
      }
    }
    .event-card__ubication {
      right: 8px;
      position: absolute;
      top: 136px;
      border: none;
      color: white;
      border-radius: 5px;
      padding: 2px 8px;
    }
  }
}

@media screen and( max-width: 350px) {
  .ant-card.event-card {
    width: 100%;
    min-width: 235px;
    height: fit-content;
    .ant-card-meta.event-card__body {
      display: flex;
      flex-direction: column;

      .ant-card-meta-avatar {
        display: flex;
        flex-direction: row;
        align-items: baseline;
        flex-wrap: wrap;
        > div {
          margin-right: 10px;
        }
      }
    }
  }
}

@primary-color: #454f58;