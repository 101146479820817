.container-subscription {
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: white;
  .banner-container {
    width: 100%;
    max-height: 50%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    .banner-loading {
      height: 200px;
    }
    img.banner {
      max-height: 50vh;
      width: 100%;
      object-fit: contain;
    }
  }
  .subscription-section {
    padding: 20px 10vw;
    background-color: white;
  }
  .ticket-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.25);
    clip-path: inset(0px 0px -15px 0px);
    position: relative;
    z-index: 2;
    @media (max-width: 700px) {
      gap: 4vw;
      flex-direction: column;
    }
    .ticket-quantity {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 4vw;
      @media (max-width: 700px) {
        flex-direction: column;
      }
      h2 {
        margin: 0;
      }
      .ant-select {
        width: 200px;
        > div {
          border-color: rgba(0, 0, 0, 0.5);
        }
        span {
          font-weight: 600;
        }
      }
    }
    .continue-button {
      border-radius: 50px;
      width: 200px;
      font-size: 16px;
      font-weight: 600;
      height: 2.5em;
    }
  }
  .details-section {
    font-weight: 500;
    .details-item {
      margin-top: 20px;
      + .details-item {
        margin-top: 10px;
      }
    }
    .description-item {
      margin-top: 50px;
      white-space: pre-line;
    }
  }
}

@primary-color: #454f58;