.tenant-page {
  display: block;
  height: 100%;
  .ant-layout {
    min-height: calc(90vh - 64px);
    height: max-content;
    justify-content: center;
  }
  .container-form-tenant {
    justify-content: center;
    display: flex;
    height: 100%;
    padding: 0 0%;
    > .content-container-center {
      width: 100%;
      height: 80%;
    }
  }
  .container-avatar {
    top: 0px;
    height: 50vh;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: white;
    background-position: center;
    margin-left: auto;
    margin-right: auto;
    background-size: cover;
  }

  .cards-events-container {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    padding: 3vw;
    background-color: white;
    > .ant-row {
      justify-content: center;
    }
  }

  .gutter-row {
    padding: 8px 0;
    justify-content: center;
  }

  .tenant-image-preview {
    width: 100%;
    height: 45vh;
    object-fit: cover;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  .events-title {
    font-size: 30px !important;
    font-weight: 400 !important;
    line-height: 35px !important;
    letter-spacing: 0em;
    text-align: center;
    font-family: 'Work Sans';
    padding-bottom: 3%;
  }

  .footer-tenant {
    height: 10vh;
    bottom: 0px;
    color: black;
  }
}

@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  src: url('../../assets/WorkSans-Regular.otf');
}

@primary-color: #454f58;