.ant-card.main-card-reminder2FA {
  width: 50vw;
  margin: 0 auto;
  box-shadow: 5px 5px 15px 5px rgba(0, 0, 0, 0.15);
  .ant-card-head-title {
    white-space: unset;
  }
  p {
    margin-bottom: 30px;
  }

  @media screen and (max-width: 768px) {
    width: 85vw;
  }
}

@primary-color: #454f58;