.landing-page {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  > .landing-page__container-banner {
    min-height: 40vh;
    padding: 30px 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: black;
    background-size: cover;
    background-position: bottom;
    > .landing-page__container-banner__title {
      color: white;
      white-space: nowrap;
    }
  }
  > .landing-page__container-cards {
    padding: 30px 50px;
    > .ant-row {
      > .landing-page__container-cards__event-subscription-card {
        max-height: 250px;
      }
      > .landing-page__container-cards__container-text-no-data {
        background-color: #2e4053;
        width: 100%;
        text-align: center;
        padding: 10px;
        border-radius: 10px;
        > .landing-page__container-cards__container-text-no-data__text-no-data {
          color: white;
          font-weight: bold;
        }
      }
    }
  }
}

@primary-color: #454f58;