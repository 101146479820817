@import '~antd/lib/style/themes/default.less';
.header-component {
  display: flex;
  justify-content: space-between;
  background-color: white;
  align-items: center;
  > .header-component--container-title-app {
    flex-grow: 0;
    min-width: 0;
    padding-right: 1rem;
    max-height: 100%;
    > h1 {
      margin: 0;
    }
  }
  > .header-component--container-menu {
    min-width: 10%;
    flex-grow: 0;
    > ul {
      div.AvatarComponent {
        margin-right: auto;
        white-space: nowrap;
        .Avatar {
          transform: translateY(-2px);
        }
        .AvatarName {
          padding-left: 6px;
          color: var(--text-color);
        }
      }
      justify-content: end;
      a {
        color: var(--text-color) !important;
      }
    }
  }
}

@primary-color: #454f58;