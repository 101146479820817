.card-only-image {
  width: 100%;
  height: 100%;
  display: flex;
  cursor: pointer;
  border-radius: 5px;
  background-position: center;
  background-size: cover;
  min-height: 250px;
  filter: drop-shadow(0px 0px 3px #d7d7d7);
  overflow: hidden;
  position: 'relative';

  > .ant-skeleton-active {
    display: block;
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;

    > .ant-skeleton-active {
      width: 100%;
      height: 100%;
    }
  }

  > .card-only-image__content {
    display: flex;
    align-items: flex-end;
    color: white;
    width: 100%;
    background: rgba(0, 0, 0, 0.7);
    padding: 20px 40px;

    > .card-only-image__content__info {
      display: flex;
      flex-direction: column;

      > .card-only-image__content__info__title,
      .card-only-image__content__info__date-start,
      .card-only-image__content__info__date-end {
        font-size: 20px;
      }

      > .card-only-image__content__info__price {
        font-weight: bold;
        font-size: 25px;
        margin-top: 20px;
        display: flex;
        align-items: center;
        > .card-only-image__content__info__price__tag {
          margin-left: 0.5rem;
          height: fit-content;
        }
      }

      @media (max-width: 400px) {
        .card-only-image__content__info__title,
        .card-only-image__content__info__date-start,
        .card-only-image__content__info__date-end {
          font-size: 15px;
        }

        .card-only-image__content__info__price {
          font-size: 20px;
        }
      }

      @media (max-width: 300px) {
        .card-only-image__content__info__title,
        .card-only-image__content__info__date-start,
        .card-only-image__content__info__date-end {
          font-size: 12px;
        }

        .card-only-image__content__info__price {
          font-size: 17px;
        }
      }
    }
  }

  > .card-only-image__content-hoverable {
    opacity: 0;
    transition: linear opacity 0.3s;
  }

  > .card-only-image__content-hoverable:hover {
    transition: linear opacity 0.3s;
    opacity: 1;
  }
}

.card-only-image.card-only-image-disabled {
  cursor: not-allowed;
}

@primary-color: #454f58;