.main-card-generate2FA__container {
  overflow-y: auto;
  max-height: 80vh;

  .main-card-generate2FA {
    width: 50vw;
    margin: 5px auto;
    margin-bottom: 30px;
    box-shadow: 5px 5px 15px 5px rgba(0, 0, 0, 0.15);
    .ant-card-head-title {
      white-space: unset;
    }
    p {
      margin-bottom: 30px;
    }

    .main-card-generate2FA__qr-container {
      width: 100%;
      margin: 30px 0;
    }
    .main-card-generate2FA__button {
      margin-top: 15px;
    }
    @media screen and (max-width: 768px) {
      width: 85vw;
    }
  }
}

@primary-color: #454f58;