@import "~antd/lib/style/themes/default.less";

div.menuTrigger {
  position: absolute;
  line-height: 64px;
  margin: 0 @margin-lg;
  transition: all 0.2s;
  > .trigger {
    cursor: pointer;
    transition: color 0.3s;
    color: @black;
  }
  > .trigger:hover {
    color: @primary-color;
  }
}

@primary-color: #454f58;