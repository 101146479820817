@import '~antd/lib/style/themes/default.less';

header.HeaderComponent {
  display: flex;
  > div.Filler {
    flex: 2;
  }
  > div.AvatarComponent {
    .Avatar {
      transform: translateY(-2px);
    }
    .AvatarName {
      padding-left: 6px;
      color: @text-color;
    }
    > .ant-dropdown-trigger {
      display: flex;
      align-items: center;
    }
  }
  > .HeaderRouteLabel {
    display: flex;
    min-width: 0;

    justify-content: center;
    align-items: center;
    .currentRouteLabelName {
      padding-left: 8px;
      font-size: 20px;
      width: min-content;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .anticon {
      font-size: 25px;
    }
  }
}

@primary-color: #454f58;