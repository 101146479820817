.ant-card.event-card-summary {
  min-width: 300px;
  max-width: 400px;
  width: 100%;
  min-height: 310px;
  height: 100%;
  border-radius: 10px;
  animation: float 6s ease-in-out infinite;
  font-family: 'Work Sans';
  &.event-card-summary--cursor-default {
    cursor: default;
  }
  .ant-card-cover {
    position: relative;
    .event-card-summary__image {
      width: 100%;
      height: 150px;
      object-fit: cover;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }
  }
  .ant-card-body {
    .ant-card-meta-avatar {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-right: 10px;
    }
    .ant-card-meta-title {
      padding-bottom: 5vmin;

      .event-card-summary__meta-container-title {
        display: flex;
        flex-direction: column;
        font-size: smaller;
        > .event-card-summary__meta-container-title__label {
          font-weight: normal;
          font-size: larger !important;
          .event-card-summary__price {
            font-weight: bold;
            top: 10px;
            left: 10px;
            display: inline;
            font-size: xx-large;
          }
        }
      }
    }
  }
  .ant-image {
    display: flex !important;
    .event-card-summary__code {
      margin: 2vmin auto 0px;
      justify-content: center;
      width: 80%;
    }
  }
}

@media screen and( max-width: 350px) {
  .ant-card.event-card-summary {
    width: 100%;
    min-width: 235px;
    height: fit-content;
    .ant-card-meta.event-card-summary__body {
      display: flex;
      flex-direction: column;
      padding-bottom: 2vmin;

      .ant-card-meta-avatar {
        display: flex;
        flex-direction: row;
        align-items: baseline;
        flex-wrap: wrap;
        > div {
          margin-right: 10px;
        }
      }
    }
  }
}

@primary-color: #454f58;